import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiAuthService } from '../services/api.auth.service';
import Bugsnag from '@bugsnag/js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ɵa } from '@cogito/angular-odoo';
@Injectable()
export class HttpInterceptor implements HttpInterceptor {
  constructor(
    private apiAuth: ApiAuthService,
    private _http: HttpClient,
    private _odooCokkies: ɵa,
  ) {}

  melissaDataHeaders(request: HttpRequest<any>) {
    return request;
  }

  equifaxHeaders(request: HttpRequest<any>) {
    // if (request.body.token) {
    //   const token = request.body.token;
    //   delete request.body.token;
    //   return request.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${token}`
    //     }
    //   });
    // }

    return request;
  }

  salesproDataHeaders(request: HttpRequest<any>, isRemote = false) {
    const token = this.apiAuth.getAuthorizationToken();
    console.log('====request======', request);
    console.log('====token======', token);

    if (!isRemote && token) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    if (isRemote && request.body && request.body.guid) {
      return request.clone({
        setHeaders: {
          guid: request.body.guid,
        },
      });
    } else if (isRemote && request.body && request.body.customerGuid) {
      return request.clone({
        setHeaders: {
          guid: request.body.customerGuid,
        },
      });
    } else {
      return request;
    }
  }
  // set header for salesproVone api urls
  salesproVoneHeaders(request: HttpRequest<any>) {
    const sessionId = this._odooCokkies.getSessionId();
    return request.clone({
      setHeaders: {
        'x-openerp-session-id': sessionId,
      },
    });
  }

  // function for replacing api url
  changeUrl(request: HttpRequest<any>) {
    const replaceUrl = environment.API_URL;
    let replacedUrl = '';
    if (request.url.includes('/api/salespro/')) {
      replacedUrl = request.url.replace('/api/salespro/', replaceUrl);
    } else {
      replacedUrl = request.url.replace('/api/', replaceUrl);
    }
    const dupReq = request.clone({ url: replacedUrl });
    return dupReq;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    Bugsnag.leaveBreadcrumb('Api Request', request);
    const prefixName = request.url.split('/')[1];
    const apiName = request.url.split('/')[2];
    const isRemote = request.url.split('/')[4] === 'remote' ? true : false;
    console.log('====prefixName==', prefixName);
    console.log('====remote==', isRemote);
    console.log('===Inside interceptor...', apiName);
    if (prefixName === 'api') {
      request = this.changeUrl(request);
      switch (apiName) {
        case 'equifax':
          request = this.equifaxHeaders(request);
          break;
        case 'melissadata':
          request = this.melissaDataHeaders(request);
          break;
        case 'salespro':
          request = this.salesproDataHeaders(request, isRemote);
          break;
        case 'salesproVOne':
        case 'salesproVTwo':
          request = this.salesproVoneHeaders(request);
          break;
        default:
          break;
      }
    }

    return next.handle(request);
  }
}
