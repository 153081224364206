import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Package } from 'app/shared/models/package.model';
import { map } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';

@Injectable()
export class PackageService  {
  onPackageChanged: BehaviorSubject<any>;
  onSearchTextChanged: Subject<any>;

  private _packages: Package[];

  constructor(private _http: HttpClient) {
    // Set the defaults
    this._packages = [];
    this.onPackageChanged = new BehaviorSubject(this._packages);
    this.onSearchTextChanged = new Subject();
  }

  // Resolver
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([this.listPackages()]).then((result) => {
        this.onSearchTextChanged.subscribe((searchText) => {
          const filteredPackages = FuseUtils.filterArrayByString(this._packages, searchText);
          this.onPackageChanged.next(filteredPackages);
        });
        resolve(this._packages);
      }, reject);
    });
  }
  /* ********************* searchPackage start ************/
  getSearchPackages(searchText: string) {
    const filteredPackages = FuseUtils.filterArrayByString(this._packages, searchText);
    return filteredPackages;
  }
  /* ********************* searchPackage End ************/

  // Add New Package Info
  addNewPackage(newPackage: Package): Promise<any> {
    const filters = {
      isDoorbellAddOn: newPackage.isDoorbellAddOn,
      isCameraAddOn: newPackage.isCameraAddOn,
      maxValue: newPackage.maxValue,
      minValue: newPackage.minValue,
      leadInfo:newPackage.leadInfo
    };
    newPackage.filters =JSON.stringify(filters)
    console.log("newwwww",newPackage);
    
    return new Promise((resolve, reject) => {
      return this._http.post(`/api/salespro/packages`, newPackage).subscribe((response: any) => {
        const updateResponse = new Package({...response,filters : JSON.parse(newPackage?.filters)});
        this._packages.push(updateResponse);
        this.onPackageChanged.next(this._packages);
        resolve(updateResponse);
      }, reject);
    });
  }

  // Get Add-On Packages
  getAddOnPackagesFromLocal(): Package[] {
    return this._packages.filter((item) => item.rmrIsAddOn === true);
  }

  // Get All Package Info
  listPackages(): Promise<Package[]> {
    return new Promise((resolve, reject) => {
      this._http.get(`/api/salespro/packages`).subscribe((response: any) => {
        this._packages = response;
        this._packages = this._packages.map((singlePackage : any)=>{
          return { ...singlePackage,
            filters : singlePackage.filters ? JSON.parse(singlePackage.filters) : null
          }
        });
        this._packages = this._packages.map((item) => {
          return new Package(item);
        });

        this.onPackageChanged.next(this._packages);
        resolve(this._packages);
      }, reject);
    });
  }

  // Get Package Info by Guid
  retrievePackage(guid: string, remote = ''): Promise<Package> {
    let sUrl = `/api/salespro/packages/${guid}`;
    if (remote === 'remote') {
      sUrl = `/api/salespro/packages/remote/${guid}`;
    }
    return this._http
      .get(sUrl)
      .pipe(map((result) => new Package(result)))
      .toPromise();
  }

  // Get Plan Packages
  getPlanPackagesFromLocal(): Package[] {
    return this._packages.filter((item) => !item.rmrIsAddOn);
  }

  // Remove the Selected Package Info
  removePackage(selectedPackage: Package): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .delete(`/api/salespro/packages/${selectedPackage.guid}`)
        .subscribe((response: any) => {
          const packageIndex = this._packages.indexOf(selectedPackage);
          this._packages.splice(packageIndex, 1);
          this.onPackageChanged.next(this._packages);
          resolve(response);
        }, reject);
    });
  }

  // Update the Selected Package Info
  updatePackage(oldPackage: Package, newPackage: Package): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log("oldddd",oldPackage, newPackage,oldPackage.guid);
     const filters = {
        isDoorbellAddOn: newPackage.isDoorbellAddOn,
        isCameraAddOn: newPackage.isCameraAddOn,
        maxValue: newPackage.maxValue,
        minValue: newPackage.minValue,
        leadInfo:newPackage.leadInfo
      };
      newPackage.filters =JSON.stringify(filters);  
      console.log("newwwwwwww",newPackage);
      console.log(oldPackage.guid);
      
      this._http
        .patch(`/api/salespro/packages/${oldPackage.guid}`, newPackage)
        .subscribe((response: any) => {
          const packageIndex = this._packages.indexOf(oldPackage);
          /*if (oldPackage && oldPackage.update) {
            oldPackage.update(newPackage);
          }*/
         const updatedPackage = new Package({...newPackage,filters : JSON.parse(newPackage?.filters)});
          this._packages[packageIndex] = updatedPackage;
          this.onPackageChanged.next(this._packages);
          resolve(response);
        }, reject);
    });
  }

  getCurrentUserId(email : string) : Promise<any>{
    return new Promise((resolve,reject)=>{
      this._http.get(`/api/salespro/permission/userid/${email}`).subscribe((response:any)=>{
        resolve(response.id);
      },reject);
    })
  }
  
  getCreditProducts(){
    return new Promise((resolve,reject) =>{
      return this._http
        .get('/api/salesproVTwo/finance/creditproducts')
        .subscribe((response:any)=>{
          resolve(response);
        }, reject);
    })
  }
}
