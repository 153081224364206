import * as _ from 'lodash';

export const getTax = (price: number): number => {
  return Number(((8.25 / 100) * price).toFixed(2));
};

export const getAmountWithTax = (price: number): number => {
  const tax = getTax(price);
  return Number(_.sum([price, tax]).toFixed(2));
};

export const getTaxRate = () => {
  return 8.25;
};

export const getAmountWithoutTax = (priceWithTax: number): number =>{
  const tax = getTaxRate();
  return Math.round(priceWithTax / (1 + tax / 100));
}